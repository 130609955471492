
            @import 'src/styles/mixins.scss';
        
.imgWrapper{
    min-height: 80px;
    position: relative;
    &>span{
        position: static !important;
        min-height: 80px;
        display: flex !important;
        width: 100% !important;
        &>img{
            position: static  !important;
            height: 100% !important;
        }
    }
}